/**
 * @type {HTMLElement[]}
 */
let elements = []

export default function fadeOutTopInit() {
   window.addEventListener("scroll", handleScroll, { passive: true })
   elements.push(...document.querySelectorAll(".fade-out-top"))
   handleScroll()
}

/**
 * @param {MouseEvent} e 
 */
function handleScroll(e) {
   elements.forEach((element, index) => {
      const { top, bottom } = element.getBoundingClientRect()

      const maskPosition = `0px ${-top + 90}px`
      element.style.maskPosition = maskPosition
      element.style.webkitMaskPosition = maskPosition
   })
}

