/*
 * This is a function that finds all text elements with given class and replaces ^^ to sup
 */

function superScriptAll(textclass) {
    const elements = document.querySelectorAll("." + textclass)
    Array(...elements).forEach((element) => {
        element.innerHTML = element.innerText
            .replace(/\^(.*)\^/, "<sup style='font-size:18px; position: relative; vertical-align: baseline; top: -0.4em'>$1</sup>")
    })
}


export default superScriptAll

