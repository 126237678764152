import colors from "tailwindcss/colors";

let elements = [];
let $colorizedElements = [];

let textColorOverride = true;

const initialize = () => {
   elements = document.querySelectorAll("section");

   setIndexToModules(elements);

   // generateColorPattern(elements);

   // Create an Intersection Observer instance
   const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
         handleModuleVisibility(entry, observer);
      });
   });

   Array.from(elements).forEach((module, index) => {
      let hasImage = moduleHasImage(module);

      let hasVideo = moduleHasVideo(module);

      let colorByImage = hasImage ? findColorsByImage(hasImage) : null;

      let imageFullWidth = hasImage ? imageHasFullWidth(hasImage) : false;

      let color = imageFullWidth
         ? colorIsDark(colorByImage)
            ? "white"
            : "black"
         : "white";

      if (hasVideo) {
         color = "white";
      }

      $colorizedElements.push({
         module: index,
         color: color,
      });

      observer.observe(module);
   });
};

/**
 * Loop trough each module within the array and give them
 * a data index attribute based on the index
 * @param Array modules
 */
const setIndexToModules = (elements) => {
   elements.forEach((element, index) => {
      element.setAttribute("data-module-index", index);
   });
};

const moduleHasVideo = (element) => {
   if (element.tagName === "IFRAME") {
      return element;
   }
   for (let i = 0; i < element.children.length; i++) {
      const childElement = element.children[i];
      const pictureElement = moduleHasVideo(childElement);
      if (pictureElement) {
         return pictureElement;
      }
   }
   return null;
}

/**
 * Check if the element contains an Image tag
 * @param HTMLElement element
 */
const moduleHasImage = (element) => {
   if (element.tagName === "IMG") {
      return element;
   }
   for (let i = 0; i < element.children.length; i++) {
      const childElement = element.children[i];
      const pictureElement = moduleHasImage(childElement);
      if (pictureElement) {
         return pictureElement;
      }
   }
   return null;
};

const imageHasFullWidth = (element) => {
   let clientWidth = element.clientWidth;
   let screenWidth = screen.width;

   return clientWidth === screenWidth;
};

/**
 * Find the dominant color by image
 * @param  HTMLElement element
 * @returns
 */
const findColorsByImage = (imgEl) => {
   var blockSize = 5, // only visit every 5 pixels
      defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
      canvas = document.createElement("canvas"),
      context = canvas.getContext && canvas.getContext("2d"),
      data,
      width,
      height,
      i = -4,
      length,
      rgb = { r: 0, g: 0, b: 0 },
      count = 0;
   if (!context) {
      return defaultRGB;
   }
   height = canvas.height =
      imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
   width = canvas.width =
      imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
   context.drawImage(imgEl, 0, 0);

   try {
      data = context.getImageData(0, 0, width, height);
   } catch (e) {
      return defaultRGB;
   }
   length = data.data.length;
   while ((i += blockSize * 4) < length) {
      ++count;
      rgb.r += data.data[i];
      rgb.g += data.data[i + 1];
      rgb.b += data.data[i + 2];
   }
   rgb.r = ~~(rgb.r / count);
   rgb.g = ~~(rgb.g / count);
   rgb.b = ~~(rgb.b / count);
   return rgb;
};

const findTextTailwindClass = (element) => {
   const classes = element.classList;

   for (let i = 0; i < classes.length; i++) {
      const classNames = classes[i].split(" ");

      for (let j = 0; j < classNames.length; j++) {
         const className = classNames[j];
         if (className.startsWith("text-")) {
            const configName = className.substring(5);
            const isValidConfigName = /^[a-z\-_]+$/i.test(configName);
            const hasNumber = /\d/.test(configName);

            if (isValidConfigName && !hasNumber) {
               return className;
            }
         }
      }
   }

   const children = element.children;
   for (let i = 0; i < children.length; i++) {
      const childResult = findTextTailwindClass(children[i]);
      if (childResult) {
         return childResult;
      }
   }

   return null;
};

const colorLuminance = (rgb) => {
   // Calculate relative luminance using the sRGB color space formula
   const luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;

   return luminance;
};

const colorIsDark = (rgb) => {
   const luminanceThreshold = 0.5;
   const luminance = colorLuminance(rgb);
   return luminance < luminanceThreshold;
};

// Function to handle the visibility changes of the module
function handleModuleVisibility(module, observer) {
   const rect = module.target.getBoundingClientRect();
   if (rect.bottom >= 0) {
      const moduleIndex = parseInt(module.target.dataset.moduleIndex);
   }
}

export default {
   initialize,
};
