const init = () => {
   const rellaxHero = document.querySelector(".rellax-hero");
   if (!rellaxHero) return

   const link = rellaxHero.parentElement.querySelector("a");
   if (!link) return 

   console.log("addevent")
   link.addEventListener("click", () => {
      const main = document.querySelector("main");
      const children = main.children;
      let target = null;

      if (children.length > 1) target = children[1];

      if (target) {
         const y = target.getBoundingClientRect().top + window.scrollY;
         window.scroll({
            top: y,
            behavior: "smooth",
         });
      }
   });
};

export default {
   init,
};
