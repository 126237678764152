import "@css/app.css";

import "lazysizes";

import Alpine from "alpinejs";
import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect);
window.Alpine = Alpine;
Alpine.start();


import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
   offset: 400, // offset (in px) from the original trigger point
   delay: 50, // values from 0 to 3000, with step 50ms
   once: true, // whether animation should happen only once - while scrolling down
   useClassNames: true,
   duration: 1000
});


import colorize from "./colorize";
import introHeroSlider from "./introHeroSlider";
import fadeIn from "./fadeInAnimation"
import fadeOutTopInit from "./fadeOutTop"
import superScriptAll from "./superscript";

colorize.initialize();
introHeroSlider.init();
fadeIn()
fadeOutTopInit()
superScriptAll("superscript")

import Rellax from "rellax";
const hero = document.querySelector(".rellax-hero");
if (hero) {
   var rellax = new Rellax(".rellax-hero", {
      speed: -2,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
   });
}

if (import.meta.hot) {
   import.meta.hot.accept(() => {
      console.log("HMR");
   });
}


/*
const mainMenu = document.getElementById("main-menu")
mainMenu.onmousemove = (e) => {
   const [width, height] = [window.innerWidth, window.innerHeight]
   const bgPos = getComputedStyle(mainMenu).backgroundPosition

   const x = parseFloat(bgPos.split(" ")[0].substring(0, bgPos.split(" ")[0].length - 1))
   const y = parseFloat(bgPos.split(" ")[1].substring(0, bgPos.split(" ")[1].length - 1))
   
   const newStyle = `${(x + e.clientX * 0.2).toFixed()}% ${(y + e.clientY * 0.2).toFixed()}% `
   console.log(newStyle)

   mainMenu.style.backgroundPosition = newStyle
}
*/