/**
 * Function for fading in text with a cool animation
 *
 * @param {string} Classname of elements you want to fade in, must be a text element
 */
export default function fadeIn(elementId = "fade-in") {
    const elements = document.querySelectorAll(`.${elementId}`)
    let firstCallbackHappened = false
    let observedElements = []
    const observer = new IntersectionObserver((e) => {
        if (!firstCallbackHappened) {
            firstCallbackHappened = true
            return
        }
        if (observedElements.includes(e[0].target)) return
        observedElements.push(e[0].target)
        e.forEach((target) => fadeInTarget(target.target))
    }, {
        threshold: 1,
    })

    elements.forEach((element) => {
        observer.observe(element)
    })
}

function fadeInTarget(element) {
    const words = element.innerText.split(" ")
    element.innerText = ""
    element.style.opacity = "1"
    let delay = 0;
    words.forEach((word) => {
        const wordSpan = document.createElement("span")
        wordSpan.style.display = "inline-block"
        word.split("").forEach((textNode, textIndex) => {
            if (textNode === " ") return
            const span = document.createElement("span")
            span.innerText = textIndex === word.length-1 ? textNode + " " : textNode
            span.classList.add("fade-in-letter")
            span.style.display = "inline-block"
            span.style.whiteSpace = "pre"
            span.style.transform = "translateY(16px)"
            span.style.animationDelay = `${delay}ms`
            delay += 5
            wordSpan.appendChild(span)
        })
        element.appendChild(wordSpan)
    })
}
